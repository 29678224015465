
import {
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  reactive,
  computed,
  onActivated,
} from "vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import Countdown from "vue3-countdown";
import CustomAudio from "@/components/CustomAudio.vue";
import ChoiceQuestion from "@/components/ChoiceQuestion.vue";
import MultipleQuestion from "@/components/MultipleQuestion.vue";
import TrueOrFalseQuestion from "@/components/TrueOrFalseQuestion.vue";
import SheetMusicCreation from "@/components/SheetMusicCreation.vue";
import SheetMusicSing from "@/components/SheetMusicSing.vue";
import SheetMusicSong from "@/components/SheetMusicSong.vue";
import FillsInQuestion from "@/components/FillsInQuestion.vue";
import BriefAnswerQuestion from "@/components/BriefAnswerQuestion.vue";
import ConnectQuestion from "@/components/ConnectQuestion.vue";
import NestingQuestion from "@/components/NestingQuestion.vue";
import JigsawQuestion from "@/components/JigsawQuestion.vue";
import DragQuestion from "@/components/DragQuestion.vue";
import {
  getOfficialTags,
  getSchoolTags,
  getDrawQuestionPool,
  getQuestionDetail,
  aiJude,
} from "@/services";
import { useStore } from "@/store";
import { MutationNames } from "@/store/index";
import _ from "lodash";
import moment from "moment";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import ColorWheelQuestion from "@/components/ColorWheelQuestion.vue";
import { checkLockFree, lock, unLock } from "@/utils/liner-lock";
import { platform as platformService } from "@/services/platform";
import VolumeSetting from "@/components/VolumeSetting.vue";

export default defineComponent({
  components: {
    Countdown,
    CustomAudio,
    ChoiceQuestion,
    MultipleQuestion,
    TrueOrFalseQuestion,
    SheetMusicCreation,
    SheetMusicSing,
    SheetMusicSong,
    FillsInQuestion,
    BriefAnswerQuestion,
    ConnectQuestion,
    NestingQuestion,
    JigsawQuestion,
    DragQuestion,
    ColorWheelQuestion,
    VolumeSetting,
  },
  setup() {
    let loading: any = ref("");
    const questionRef: any = ref<null | HTMLElement>(null);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const name = ref(store.state.name);
    const grade = ref(store.state.grade);
    const classNumber = ref(store.state.classNumber);
    let LeftTxt = ref(
      "请在右侧选择题型等条件，点击抽取题目，即可开始自由练习。"
    );
    let showQuestion = ref(true);
    let FPBtnTxt = ref("提交");
    let questionPools: any = ref([]); // 抽取题目数据
    let questionPoolIndex: any = ref(0); // 当前抽取题目题号
    let question: any = ref({}); // 试题信息
    let questionDisable = ref(false); // 题目是否可以编辑，是否显示正确答案
    let freePracticeQuestions = reactive<any>([]); // 已完成最多20题的自由练习题目
    let freePracticeNum = ref(1); // 总共做的自由练习题目数
    let flagFPN = ref(false); // 点击抽取题目时是否需要将自由练习题目数+1
    let freePracticeLoading = ref(false); // 控制loading的变量
    let freePracticeLoadingText = ref("处理中..."); // loading信息
    // 标签
    const tagValue = ref([]);
    // 难度
    const difficultyValue = ref("");
    // 关键字
    const keyValue = ref("");
    // 题型
    const typeValue = ref("");

    // 题型
    const questionType = [
      {
        value: "1",
        label: "单选题",
      },
      {
        value: "2",
        label: "多选题",
      },
      {
        value: "4",
        label: "判断题",
      },
      {
        value: "3",
        label: "编创题",
      },
      {
        value: "5",
        label: "演唱题",
      },
      {
        value: "6",
        label: "演奏题",
      },
      {
        value: "7",
        label: "视唱题",
      },
      {
        value: "8",
        label: "连线题",
      },
      {
        value: "9",
        label: "填空题",
      },
      {
        value: "10",
        label: "简答题",
      },
      {
        value: "11",
        label: "套题",
      },
      {
        value: "12",
        label: "拖拽题",
      },
      {
        value: "13",
        label: "色环题",
      },
      {
        value: "14",
        label: "拼图题",
      },
    ];
    // 难度
    const difficulty = [
      {
        value: "0",
        label: "简单",
      },
      {
        value: "1",
        label: "中等",
      },
      {
        value: "2",
        label: "困难",
      },
    ];
    // 题型
    let tag: any = ref([]);
    const goBack = () => {
      freePracticeQuestions.length = 0;
      store.commit(MutationNames.CHANGE_FREEPRACTICEQUESTIONS, []);
      showQuestion.value = true;
      questionPools.value = [];
      questionPoolIndex.value = 0;
      question.value = {};
      questionDisable.value = false;
      freePracticeNum.value = 1;
      flagFPN.value = false;
      typeValue.value = "";
      keyValue.value = "";
      difficultyValue.value = "";
      tagValue.value = [];

      router.push({
        path: "/home",
      });
    };
    const goRecord = () => {
      if (freePracticeQuestions.length > 0) {
        store.commit(
          MutationNames.CHANGE_FREEPRACTICEQUESTIONS,
          freePracticeQuestions
        );
        router.push({
          path: "/freePracticeRecord",
        });
      } else {
        ElMessage({
          showClose: true,
          duration: 5000,
          offset: 160,
          message: "请先作答练习！",
          type: "error",
        });
      }
    };
    // 处理答案
    const saveAnswerInfo = async (params: any) => {
      console.log("处理答案params", params);
      // 在题目信息中添加作答时间
      question.value.FPSubmitDate = moment(new Date()).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      let result: any = null;
      const source = questionPools.value.find(
        (pool: any) => pool.question_id === question.value.id
      ).source;
      if (question.value.type !== 11) {
        if (!params.answer_params) {
          question.value.AnswerIsSuccess = false;
          question.value.AnswerIsSuccessText = "错误";
          return;
        }
        result = await aiJude({
          source,
          question_id: question.value.id,
          answer_params: params.answer_params,
          ai_raw_score: params.answer_params?.aiScore,
        });
      } else {
        if (!params.answer_params) {
          question.value.AnswerIsSuccess = false;
          question.value.AnswerIsSuccessText = "错误";
          return;
        }
        const { questionId, answer = {} } = params.answer_params;
        const { sub_questions = [] } = question.value;
        result = await aiJude({
          source,
          question_id: questionId,
          answer_params: answer.answer_params,
          ai_raw_score: answer.answer_params?.aiScore,
        });
        const sub_question = sub_questions.find(
          (sub_question: any) => sub_question.id === questionId
        );
        sub_question.correctness = result.correctness;
        sub_question.answerScore = result.score;
        result.score = _.sumBy(sub_questions, "answerScore");
        const subAllCorrectness = sub_questions
          .map((sub_question: any) => sub_question.correctness)
          .filter((i: any) => i !== undefined);
        const isAllCorrect =
          subAllCorrectness.filter((i: any) => i === 1).length ===
          sub_questions.length;
        const isSomeCorrect = subAllCorrectness.includes(1);
        result.correctness = isAllCorrect ? 1 : isSomeCorrect ? 2 : 0;
      }
      question.value.answerScore = result.score;
      question.value.AnswerIsSuccess = result.correctness === 1;
      question.value.AnswerIsSuccessText =
        result.correctness == 1
          ? "正确"
          : result.correctness == 2
          ? "部分正确"
          : "错误";

      // return false;
    };
    const showPreviousBtn = computed(() => {
      const { type } = question.value;
      if (type !== 11) {
        return false;
      }
      const { isFirstSubQuestion } = questionRef.value || {};
      if (!isFirstSubQuestion) {
        return false;
      }
      return !isFirstSubQuestion();
    });

    const goPrevious = () => {
      const { changeSubQuestion } = questionRef.value || {};
      questionDisable.value = true;
      changeSubQuestion("previous");
    };
    // 下一题 提交
    const dvFPBtn = _.throttle(async () => {
      /**
       * 1. 判断是套题 还是普通题目
       * 2. 普通题目 判断是下一题 还是提交
       * 3. 套题题型
       *    3.1 最后一题 走提交 然后走普通题目
       *    3.2 不是最后一题 走下小题
       */
      await checkLockFree();
      // 提交题目
      const confirmQuestion = async () => {
        try {
          // loading.value = ElLoading.service({
          //   lock: true,
          //   text: "提交中...",
          //   background: "rgba(0, 0, 0, 0.3)",
          // });
          freePracticeLoadingText.value = "提交中...";
          freePracticeLoading.value = true;
          const AnswerInfo = await questionRef.value.getAnswer();
          console.log("AnswerInfo112", AnswerInfo);
          await changePartsAnswer(AnswerInfo);
        } catch (e) {
          console.error(e);
          ElMessage({
            type: "warning",
            offset: 100,
            message: "保存答案失败，请重试！",
            center: true,
          });
          unLock();
        } finally {
          // loading.value && loading.value.close();
          freePracticeLoading.value = false;
        }
      };

      const goNext = () => {
        lock();
        freePracticeNum.value++;
        questionPoolIndex.value++;
        flagFPN.value = false;

        getQuestionData(questionPools.value[questionPoolIndex.value]);
      };
      const { type } = question.value;
      if (type !== 11) {
        if (FPBtnTxt.value === "下一题") {
          // 是否为最后一道题目
          if (questionPoolIndex.value + 1 < questionPools.value.length) {
            goNext();
            questionDisable.value = false;
            FPBtnTxt.value = "提交";
          } else {
            ElMessage({
              showClose: true,
              duration: 5000,
              offset: 160,
              message: "已是最后一题！",
              type: "error",
            });
          }
        } else {
          // 当题目不为套题时，点击提交按钮，将抽取题目时题目数是否+1的标志改为true(相当于点击抽取题目题目数+1)
          flagFPN.value = true;
          // loading.value = ElLoading.service({
          //   lock: true,
          //   text: "处理中...",
          //   background: "rgba(0, 0, 0, 0.3)",
          // });
          freePracticeLoadingText.value = "处理中...";
          freePracticeLoading.value = true;
          await confirmQuestion();
          questionDisable.value = true;
          FPBtnTxt.value = "下一题";
        }
      } else {
        const { isLastSubQuestion, changeSubQuestion } = questionRef.value;
        if (FPBtnTxt.value === "下一题" && !isLastSubQuestion()) {
          await confirmQuestion();
          questionDisable.value = false;
          changeSubQuestion("next");
          FPBtnTxt.value = "提交";
          // isLastSubQuestion()
          //   ? (FPBtnTxt.value = "提交")
          //   : (FPBtnTxt.value = "下一题");
        } else if (FPBtnTxt.value === "下一题" && isLastSubQuestion()) {
          if (questionPoolIndex.value + 1 < questionPools.value.length) {
            goNext();
            FPBtnTxt.value = "提交";
            questionDisable.value = false;
            FPBtnTxt.value = "提交";
          } else {
            ElMessage({
              showClose: true,
              duration: 5000,
              offset: 160,
              message: "已是最后一题！",
              type: "error",
            });
          }
        } else if (FPBtnTxt.value === "提交" && isLastSubQuestion()) {
          // 当题目为套题时，并且是套题的最后一题，点击提交按钮，将抽取题目时题目数是否+1的标志改为true(相当于点击抽取题目题目数+1)
          flagFPN.value = true;
          await confirmQuestion();
          questionDisable.value = true;
          FPBtnTxt.value = "下一题";
        } else {
          await confirmQuestion();
          questionDisable.value = true;
          FPBtnTxt.value = "下一题";
        }
      }
    }, 1000);

    // 获取用户作答答案，并且保存到练习记录
    const changePartsAnswer = async (params: any) => {
      console.log("获取用户作答记录", params);
      freePracticeNum.value;
      if (question.value.type !== 11) {
        question.value.answer_params = params.answer_params;
      } else {
        const { sub_questions = [] } = question.value;
        const { questionId, answer = {} } = params.answer_params || {};

        const sub_question = sub_questions.find(
          (subQuestion: any) => subQuestion.id == questionId
        );
        _.merge(sub_question || {}, { answer_params: answer.answer_params });
      }
      // saveAnswerInfo();
      await saveAnswerInfo(params);
      const { isLastSubQuestion } = questionRef.value;
      if (
        question.value.type !== 11 ||
        (question.value.type === 11 && isLastSubQuestion && isLastSubQuestion())
      ) {
        freePracticeQuestions.unshift(question.value);
        if (freePracticeQuestions.length > 20) {
          freePracticeQuestions.pop();
        }
      }
    };
    // 获取题目详情
    const getQuestionData = (params: any) => {
      getQuestionDetail(params).then((data: any) => {
        question.value = {
          type: -1,
        };
        setTimeout(() => {
          question.value = data;
          // if (question.value.type === 11) {
          //   FPBtnTxt.value = "提交";
          // }
        }, 0);
      });
    };
    // 抽取题目
    const getQuestions = () => {
      if (flagFPN.value) {
        freePracticeNum.value++;
        questionPoolIndex.value++;
        // 是否在抽取题目时将题目数+1标志为改为false
        flagFPN.value = false;
      }
      FPBtnTxt.value = "提交";
      // if (typeValue.value) {
      const params = {
        tags: tagValue.value,
        level: difficultyValue.value,
        keyword: keyValue.value,
        type: typeValue.value,
      };
      getDrawQuestionPool(params).then((data: any) => {
        if (data.question_pools.length > 0) {
          questionPoolIndex.value = 0;
          questionDisable.value = false;
          questionPools.value = data.question_pools;
          getQuestionData(data.question_pools[questionPoolIndex.value]);
          showQuestion.value = false;
        } else {
          LeftTxt.value = "暂无题目";
          showQuestion.value = true;
        }
      });
      // } else {
      //   ElMessage({
      //     showClose: true,
      //     duration: 5000,
      //     offset: 160,
      //     message: "题型是必选项！",
      //     type: "error",
      //   });
      // }
    };

    // 动态配置路由keepAlive
    const changeRouterKeepAlive = (name: string, keepAlive: boolean) => {
      router.options.routes.map((item: any) => {
        if (item.name === name) {
          item.meta.keepAlive = keepAlive;
        }
      });
    };
    onBeforeMount(async () => {
      let paramsSchool: any = [];
      let paramsOfficial: any = [];
      console.time("时间评估: 自由练习获取数据");
      await getSchoolTags({ per_page: 1000 }).then((data: any) => {
        // const params: any = [];
        data.items.forEach((item: any) => {
          const data = {
            value: item.tag,
            label: item.tag,
          };
          paramsSchool.push(data);
        });
        // tag.value = params;
      });
      await getOfficialTags({ per_page: 1000 }).then((data: any) => {
        // const params: any = [];
        data.items.forEach((item: any) => {
          const data = {
            value: item.tag,
            label: item.tag,
          };
          paramsOfficial.push(data);
        });
        // tag.value = params;
      });
      // const newSet = new Set(paramsOfficial);
      // paramsSchool.map((data: any) => {
      //   newSet.add(data);
      // });
      // tag.value = [...newSet].sort();
      let data: any = [...paramsOfficial, ...paramsSchool];
      tag.value = _.uniqBy(data, "value");
      console.timeEnd("时间评估: 自由练习获取数据");
    });

    const onQuestionCompError = (error: any) => {
      store.commit(MutationNames.CHANGE_EXAMERROE, true);
      const msg = typeof error === "string" ? error : error.message;
      console.log("onQuestionCompError");
      ElMessageBox.confirm(`${msg}, 返回登录页`, "系统出错", {
        confirmButtonText: "确定",
        type: "warning",
        center: true,
        showClose: false,
        showCancelButton: false,
        closeOnClickModal: false,
        customClass: "exam-score-message-box",
      }).then(() => {
        unLock();
        // loading.value && loading.value.close();
        freePracticeLoading.value = false;
        store.commit(MutationNames.CHANGE_EXAMERROE, false);
        store.commit(MutationNames.CHANGE_EXAMEND, false);
        let isAndroid = true;
        const device = navigator.userAgent;
        isAndroid =
          device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
        if (isAndroid) {
          console.log("catch通知android");
          platformService.goLogin();
        } else {
          console.log("catch通知pc");
          router.push({
            path: "/login",
          });
        }
      });
    };

    onMounted(() => {
      console.log(999);
      console.timeEnd("自由练习加载时间");
      // setTimeout(() => {
      //   router.options.routes.map((item: any) => {
      //     if (item.name === "FreePractice") {
      //       console.log(12123, item);
      //       item.meta = {
      //         title: "FreePractice",
      //         keepAlive: true,
      //       };
      //     }
      //   });
      // }, 5000);
    });
    onBeforeRouteLeave((to, from) => {
      // if (to.name !== "FreePracticeRecord") {
      //   changeRouterKeepAlive(from.name as string, false);
      // } else {
      //   changeRouterKeepAlive(from.name as string, true);
      // }
    });

    // 组件的兼容，强制的刷新让他触发页面刷新
    onActivated(async () => {
      name.value = store.state.name;
      grade.value = store.state.grade;
      classNumber.value = store.state.classNumber;
      if (questionRef.value && question.value.type !== 11) {
        const data = question.value;
        question.value = {};
        setTimeout(() => {
          question.value = data;
        }, 0);
      }
    });

    return {
      questionRef,
      name,
      grade,
      classNumber,
      tagValue,
      difficultyValue,
      typeValue,
      keyValue,
      questionType,
      difficulty,
      tag,
      LeftTxt,
      showQuestion,
      FPBtnTxt,
      question,
      questionDisable,
      goBack,
      getQuestions,
      dvFPBtn,
      changePartsAnswer,
      goRecord,
      showPreviousBtn,
      goPrevious,
      questionPools,
      loading,
      onQuestionCompError,
      freePracticeLoading,
      freePracticeLoadingText,
    };
  },
});
